var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dealerStock" },
    [
      _c("div", { staticClass: "head" }, [
        _c("ul", [
          _c(
            "li",
            [
              _c("h5", [_vm._v("品质仓")]),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.form.position,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "position", $$v)
                    },
                    expression: "form.position",
                  },
                },
                _vm._l(_vm.dealers, function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.valueCn, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("h5", [_vm._v("区域仓")]),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.form.locationPosition,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "locationPosition", $$v)
                    },
                    expression: "form.locationPosition",
                  },
                },
                _vm._l(_vm.locationPositions, function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.valueCn, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("h5", [_vm._v("产品编号")]),
              _vm._v(" "),
              _c("el-input", {
                model: {
                  value: _vm.form.productNo,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "productNo", $$v)
                  },
                  expression: "form.productNo",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.search } },
                [_vm._v("查询")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.empty } },
                [_vm._v("清空")]
              ),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "function" }, [
        _c("ol", [
          _c(
            "li",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit",
                  value: "/order/dealer/stock/put",
                  expression: "'/order/dealer/stock/put'",
                },
              ],
              on: { click: _vm.stockin },
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/iconfont/put.png"),
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("入库")]),
            ]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit",
                  value: "/order/dealer/stock/out",
                  expression: "'/order/dealer/stock/out'",
                },
              ],
              on: { click: _vm.exportant },
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/iconfont/exports.png"),
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("出库")]),
            ]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit",
                  value: "/order/dealer/stock/save",
                  expression: "'/order/dealer/stock/save'",
                },
              ],
              on: { click: _vm.add },
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/iconfont/pinzhicang.png"),
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("品质仓调整")]),
            ]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit",
                  value: "/order/dealer/stock/location/update",
                  expression: "'/order/dealer/stock/location/update'",
                },
              ],
              on: { click: _vm.locationUpdate },
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/iconfont/quyucang.png"),
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("区域仓调整")]),
            ]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit",
                  value: "/order/dealer/stock/into/import",
                  expression: "'/order/dealer/stock/into/import'",
                },
              ],
            },
            [
              _c("div", { staticClass: "upload-wrapper" }, [
                _c("img", {
                  attrs: {
                    src: require("../../assets/iconfont/to.png"),
                    alt: "",
                  },
                }),
                _vm._v(" "),
                _c("input", {
                  ref: "uploadFile",
                  staticClass: "upload-file",
                  attrs: {
                    id: "file",
                    type: "file",
                    name: "file",
                    multiple: "",
                  },
                  on: { change: _vm.submitUpload },
                }),
                _vm._v(" "),
                _c("span", [_vm._v("批入")]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "li",
            { staticStyle: { "z-index": "1000" }, on: { click: _vm.down } },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/iconfont/down.png"),
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("批入模板")]),
            ]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit",
                  value: "/order/dealer/stock/import",
                  expression: "'/order/dealer/stock/import'",
                },
              ],
              on: { click: _vm.sallout },
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/iconfont/exports.png"),
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("批出")]),
            ]
          ),
          _vm._v(" "),
          _c(
            "li",
            { staticStyle: { "z-index": "1000" }, on: { click: _vm.downout } },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/iconfont/export.png"),
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("批出模板")]),
            ]
          ),
          _vm._v(" "),
          _c(
            "li",
            { staticStyle: { "z-index": "1000" }, on: { click: _vm.usCount } },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/iconfont/inspection.png"),
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("美国仓库存")]),
            ]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              staticStyle: { "z-index": "1000" },
              on: { click: _vm.usrwCount },
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/iconfont/inspection.png"),
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("美国售后仓库存")]),
            ]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit",
                  value: "/order/dealer/stock/delete",
                  expression: "'/order/dealer/stock/delete'",
                },
              ],
              on: { click: _vm.dele },
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/iconfont/delete.png"),
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("删除")]),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticStyle: { width: "100%", "margin-top": "30px" },
          attrs: { data: _vm.tableData, border: "" },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", type: "selection", width: "55" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "产品编号",
              "show-overflow-tooltip": "",
              prop: "productNo",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "产品名称",
              "show-overflow-tooltip": "",
              prop: "productName",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "品质仓",
              "show-overflow-tooltip": "",
              prop: "position",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "区域仓",
              "show-overflow-tooltip": "",
              prop: "locationPosition",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "入库总数",
              "show-overflow-tooltip": "",
              prop: "countSum",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "可发货数量",
              "show-overflow-tooltip": "",
              prop: "counts",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "备注",
              "show-overflow-tooltip": "",
              prop: "remark",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "操作人",
              "show-overflow-tooltip": "",
              prop: "createUser",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "操作时间",
              "show-overflow-tooltip": "",
              prop: "createTime",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "addform",
          attrs: {
            title: _vm.title,
            visible: _vm.dialogFormVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
            close: function ($event) {
              return _vm.addClose("addform")
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "addform",
              attrs: {
                model: _vm.addform,
                "status-icon": true,
                "show-message": false,
                "hide-required-asterisk": "",
              },
            },
            [
              _vm.typeflags
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "类型",
                        "label-width": _vm.formLabelWidth,
                      },
                    },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "1" },
                          on: { change: _vm.isNo },
                          model: {
                            value: _vm.addStatus,
                            callback: function ($$v) {
                              _vm.addStatus = $$v
                            },
                            expression: "addStatus",
                          },
                        },
                        [_vm._v("设备")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "2" },
                          on: { change: _vm.isNo },
                          model: {
                            value: _vm.addStatus,
                            callback: function ($$v) {
                              _vm.addStatus = $$v
                            },
                            expression: "addStatus",
                          },
                        },
                        [_vm._v("封套")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "3" },
                          on: { change: _vm.isNo },
                          model: {
                            value: _vm.addStatus,
                            callback: function ($$v) {
                              _vm.addStatus = $$v
                            },
                            expression: "addStatus",
                          },
                        },
                        [_vm._v("笔")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "4" },
                          on: { change: _vm.isNo },
                          model: {
                            value: _vm.addStatus,
                            callback: function ($$v) {
                              _vm.addStatus = $$v
                            },
                            expression: "addStatus",
                          },
                        },
                        [_vm._v("笔芯")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "产品:",
                    "label-width": _vm.formLabelWidth,
                    prop: "productNo",
                  },
                },
                [
                  _vm.typeflag
                    ? _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { placeholder: "请选择", filterable: "" },
                          on: { change: _vm.changeProduct },
                          model: {
                            value: _vm.addform.productNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.addform, "productNo", $$v)
                            },
                            expression: "addform.productNo",
                          },
                        },
                        _vm._l(_vm.type, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.valueCn, value: item.value },
                          })
                        }),
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.nameflag
                    ? _c("div", [_vm._v(_vm._s(_vm.productName))])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.coverflag
                    ? _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { filterable: "", placeholder: "请选择" },
                          on: { change: _vm.changeProduct },
                          model: {
                            value: _vm.addform.productNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.addform, "productNo", $$v)
                            },
                            expression: "addform.productNo",
                          },
                        },
                        _vm._l(_vm.jacket, function (item, index) {
                          return !item.value.includes("SP") &&
                            !item.value.includes("SR")
                            ? _c("el-option", {
                                key: index,
                                attrs: {
                                  label: item.value + "_" + item.valueCn,
                                  value: item.value,
                                },
                              })
                            : _vm._e()
                        }),
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.penflag
                    ? _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { filterable: "", placeholder: "请选择" },
                          on: { change: _vm.changeProduct },
                          model: {
                            value: _vm.addform.productNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.addform, "productNo", $$v)
                            },
                            expression: "addform.productNo",
                          },
                        },
                        _vm._l(_vm.jacket, function (item, index) {
                          return item.value.includes("SP")
                            ? _c("el-option", {
                                key: index,
                                attrs: {
                                  label: item.value + "_" + item.valueCn,
                                  value: item.value,
                                },
                              })
                            : _vm._e()
                        }),
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.refillflag
                    ? _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { filterable: "", placeholder: "请选择" },
                          on: { change: _vm.changeProduct },
                          model: {
                            value: _vm.stockIn.productNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.stockIn, "productNo", $$v)
                            },
                            expression: "stockIn.productNo",
                          },
                        },
                        _vm._l(_vm.jacket, function (item, index) {
                          return item.value.includes("SR")
                            ? _c("el-option", {
                                key: index,
                                attrs: {
                                  label: item.value + "_" + item.valueCn,
                                  value: item.value,
                                },
                              })
                            : _vm._e()
                        }),
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: { label: "仓位:", "label-width": _vm.formLabelWidth },
                },
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading",
                        },
                      ],
                      ref: "singeltatbel",
                      staticClass: "table",
                      staticStyle: { width: "100%" },
                      attrs: { data: _vm.tableDatas, border: "" },
                      on: { "selection-change": _vm.handleSelectionChanges },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { type: "selection", width: "55" },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "仓位",
                          formatter: _vm.dealerList,
                          "show-overflow-tooltip": "",
                          prop: "value",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "数量",
                          prop: "counts",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c("el-input-number", {
                                  attrs: { controls: false, label: "描述文字" },
                                  model: {
                                    value: row.counts,
                                    callback: function ($$v) {
                                      _vm.$set(row, "counts", $$v)
                                    },
                                    expression: "row.counts",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "备注:",
                    "label-width": _vm.formLabelWidth,
                    prop: "remark",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.addform.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.addform, "remark", $$v)
                      },
                      expression: "addform.remark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.addClose("addform")
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: _vm.isDisable },
                  on: {
                    click: function ($event) {
                      return _vm.keep("addform")
                    },
                  },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "入库",
            visible: _vm.dialogFormVisibles,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisibles = $event
            },
            close: function ($event) {
              return _vm.stockClose("stockIn")
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "stockIn",
              attrs: {
                model: _vm.stockIn,
                "status-icon": true,
                "show-message": false,
                "hide-required-asterisk": "",
              },
            },
            [
              _vm.typeflags
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "类型",
                        "label-width": _vm.formLabelWidth,
                      },
                    },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "1" },
                          on: { change: _vm.isNo },
                          model: {
                            value: _vm.status,
                            callback: function ($$v) {
                              _vm.status = $$v
                            },
                            expression: "status",
                          },
                        },
                        [_vm._v("设备11")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "2" },
                          on: { change: _vm.isNo },
                          model: {
                            value: _vm.status,
                            callback: function ($$v) {
                              _vm.status = $$v
                            },
                            expression: "status",
                          },
                        },
                        [_vm._v("封套")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "3" },
                          on: { change: _vm.isNo },
                          model: {
                            value: _vm.status,
                            callback: function ($$v) {
                              _vm.status = $$v
                            },
                            expression: "status",
                          },
                        },
                        [_vm._v("笔")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "4" },
                          on: { change: _vm.isNo },
                          model: {
                            value: _vm.status,
                            callback: function ($$v) {
                              _vm.status = $$v
                            },
                            expression: "status",
                          },
                        },
                        [_vm._v("笔芯")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "产品:",
                    "label-width": _vm.formLabelWidth,
                    prop: "productNo",
                  },
                },
                [
                  _vm.typeflag
                    ? _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { placeholder: "请选择", filterable: "" },
                          model: {
                            value: _vm.stockIn.productNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.stockIn, "productNo", $$v)
                            },
                            expression: "stockIn.productNo",
                          },
                        },
                        _vm._l(_vm.type, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.valueCn, value: item.value },
                          })
                        }),
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.nameflag
                    ? _c("div", [_vm._v(_vm._s(_vm.productName))])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.coverflag
                    ? _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { filterable: "", placeholder: "请选择" },
                          model: {
                            value: _vm.stockIn.productNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.stockIn, "productNo", $$v)
                            },
                            expression: "stockIn.productNo",
                          },
                        },
                        _vm._l(_vm.jacket, function (item, index) {
                          return !item.value.includes("SP") &&
                            !item.value.includes("SR")
                            ? _c("el-option", {
                                key: index,
                                attrs: {
                                  label: item.value + "_" + item.valueCn,
                                  value: item.value,
                                },
                              })
                            : _vm._e()
                        }),
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.penflag
                    ? _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { filterable: "", placeholder: "请选择" },
                          model: {
                            value: _vm.stockIn.productNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.stockIn, "productNo", $$v)
                            },
                            expression: "stockIn.productNo",
                          },
                        },
                        _vm._l(_vm.jacket, function (item, index) {
                          return item.value.includes("SP")
                            ? _c("el-option", {
                                key: index,
                                attrs: {
                                  label: item.value + "_" + item.valueCn,
                                  value: item.value,
                                },
                              })
                            : _vm._e()
                        }),
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.refillflag
                    ? _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { filterable: "", placeholder: "请选择" },
                          model: {
                            value: _vm.stockIn.productNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.stockIn, "productNo", $$v)
                            },
                            expression: "stockIn.productNo",
                          },
                        },
                        _vm._l(_vm.jacket, function (item, index) {
                          return item.value.includes("SR")
                            ? _c("el-option", {
                                key: index,
                                attrs: {
                                  label: item.value + "_" + item.valueCn,
                                  value: item.value,
                                },
                              })
                            : _vm._e()
                        }),
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: { label: "仓位:", "label-width": _vm.formLabelWidth },
                },
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading",
                        },
                      ],
                      ref: "singeltatbel",
                      staticClass: "table",
                      staticStyle: { width: "100%" },
                      attrs: { data: _vm.tableDatas, border: "" },
                      on: { "selection-change": _vm.handleSelectionChanges },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { type: "selection", width: "55" },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "仓位",
                          formatter: _vm.dealerList,
                          "show-overflow-tooltip": "",
                          prop: "value",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "数量",
                          prop: "counts",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c("el-input-number", {
                                  attrs: { controls: false, label: "描述文字" },
                                  model: {
                                    value: row.counts,
                                    callback: function ($$v) {
                                      _vm.$set(row, "counts", $$v)
                                    },
                                    expression: "row.counts",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "供应商:",
                    "label-width": _vm.formLabelWidth,
                    prop: "supplier",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { maxlength: "255", autocomplete: "off" },
                    model: {
                      value: _vm.stockIn.supplier,
                      callback: function ($$v) {
                        _vm.$set(_vm.stockIn, "supplier", $$v)
                      },
                      expression: "stockIn.supplier",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "备注:",
                    "label-width": _vm.formLabelWidth,
                    prop: "remark",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.stockIn.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.stockIn, "remark", $$v)
                      },
                      expression: "stockIn.remark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.stockClose("stockIn")
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.surcLoading,
                      expression: "surcLoading",
                    },
                  ],
                  attrs: { type: "primary", disabled: _vm.isDisable },
                  on: {
                    click: function ($event) {
                      return _vm.stockSure("stockIn")
                    },
                  },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.outTitle,
            "close-on-click-modal": false,
            visible: _vm.dialogExportForm,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogExportForm = $event
            },
            close: function ($event) {
              return _vm.exportClose("exportform")
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "exportform",
              attrs: {
                model: _vm.exportform,
                rules: _vm.exportformrules,
                "status-icon": true,
                "show-message": false,
                "hide-required-asterisk": "",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "类型",
                    "label-width": _vm.formLabelWidth,
                    prop: "type",
                  },
                },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "1" },
                      on: { change: _vm.isProduct },
                      model: {
                        value: _vm.exporttypes,
                        callback: function ($$v) {
                          _vm.exporttypes = $$v
                        },
                        expression: "exporttypes",
                      },
                    },
                    [_vm._v("设备")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "2" },
                      on: { change: _vm.isProduct },
                      model: {
                        value: _vm.exporttypes,
                        callback: function ($$v) {
                          _vm.exporttypes = $$v
                        },
                        expression: "exporttypes",
                      },
                    },
                    [_vm._v("封套")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "3" },
                      on: { change: _vm.isProduct },
                      model: {
                        value: _vm.exporttypes,
                        callback: function ($$v) {
                          _vm.exporttypes = $$v
                        },
                        expression: "exporttypes",
                      },
                    },
                    [_vm._v("笔")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "4" },
                      on: { change: _vm.isProduct },
                      model: {
                        value: _vm.exporttypes,
                        callback: function ($$v) {
                          _vm.exporttypes = $$v
                        },
                        expression: "exporttypes",
                      },
                    },
                    [_vm._v("笔芯")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "产品:",
                    "label-width": _vm.formLabelWidth,
                    prop: "productNo",
                  },
                },
                [
                  _vm.typeflag
                    ? _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { placeholder: "请选择", filterable: "" },
                          model: {
                            value: _vm.exportform.productNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.exportform, "productNo", $$v)
                            },
                            expression: "exportform.productNo",
                          },
                        },
                        _vm._l(_vm.type, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.valueCn, value: item.value },
                          })
                        }),
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.nameflag
                    ? _c("div", [_vm._v(_vm._s(_vm.productName))])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.coverflag
                    ? _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { filterable: "", placeholder: "请选择" },
                          model: {
                            value: _vm.exportform.productNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.exportform, "productNo", $$v)
                            },
                            expression: "exportform.productNo",
                          },
                        },
                        _vm._l(_vm.jacket, function (item, index) {
                          return !item.value.includes("SP") &&
                            !item.value.includes("SR")
                            ? _c("el-option", {
                                key: index,
                                attrs: {
                                  label: item.value + "_" + item.valueCn,
                                  value: item.value,
                                },
                              })
                            : _vm._e()
                        }),
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.penflag
                    ? _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { filterable: "", placeholder: "请选择" },
                          model: {
                            value: _vm.exportform.productNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.exportform, "productNo", $$v)
                            },
                            expression: "exportform.productNo",
                          },
                        },
                        _vm._l(_vm.jacket, function (item, index) {
                          return item.value.includes("SP")
                            ? _c("el-option", {
                                key: index,
                                attrs: {
                                  label: item.value + "_" + item.valueCn,
                                  value: item.value,
                                },
                              })
                            : _vm._e()
                        }),
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.refillflag
                    ? _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { filterable: "", placeholder: "请选择" },
                          model: {
                            value: _vm.exportform.productNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.exportform, "productNo", $$v)
                            },
                            expression: "exportform.productNo",
                          },
                        },
                        _vm._l(_vm.jacket, function (item, index) {
                          return item.value.includes("SR")
                            ? _c("el-option", {
                                key: index,
                                attrs: {
                                  label: item.value + "_" + item.valueCn,
                                  value: item.value,
                                },
                              })
                            : _vm._e()
                        }),
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "品质仓",
                    "label-width": _vm.formLabelWidth,
                    prop: "position",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.exportform.position,
                        callback: function ($$v) {
                          _vm.$set(_vm.exportform, "position", $$v)
                        },
                        expression: "exportform.position",
                      },
                    },
                    _vm._l(_vm.dealers, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.valueCn, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "区域仓",
                    "label-width": _vm.formLabelWidth,
                    prop: "locationPosition",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.exportform.locationPosition,
                        callback: function ($$v) {
                          _vm.$set(_vm.exportform, "locationPosition", $$v)
                        },
                        expression: "exportform.locationPosition",
                      },
                    },
                    _vm._l(_vm.locationPositions, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.valueCn, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.deaFlag
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "经销商",
                        "label-width": _vm.formLabelWidth,
                        prop: "dealer",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.exportform.dealer,
                            callback: function ($$v) {
                              _vm.$set(_vm.exportform, "dealer", $$v)
                            },
                            expression: "exportform.dealer",
                          },
                        },
                        _vm._l(_vm.dealerLists, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.valueCn, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.dealeFlags
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "供应商",
                        "label-width": _vm.formLabelWidth,
                        prop: "supplier",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { autocomplete: "off" },
                        model: {
                          value: _vm.exportform.supplier,
                          callback: function ($$v) {
                            _vm.$set(_vm.exportform, "supplier", $$v)
                          },
                          expression: "exportform.supplier",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                [
                  _vm.exportFlags
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "设备用途",
                            "label-width": _vm.formLabelWidth,
                            prop: "equipmentPurpose",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.exportform.equipmentPurpose,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.exportform,
                                    "equipmentPurpose",
                                    $$v
                                  )
                                },
                                expression: "exportform.equipmentPurpose",
                              },
                            },
                            _vm._l(_vm.equipmentPurposes, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: {
                                  label: item.valueCn,
                                  value: item.value,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.batchFlag
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "批次号",
                            "label-width": _vm.formLabelWidth,
                            prop: "batchNo",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { autocomplete: "off", maxlength: "30" },
                            model: {
                              value: _vm.exportform.batchNo,
                              callback: function ($$v) {
                                _vm.$set(_vm.exportform, "batchNo", $$v)
                              },
                              expression: "exportform.batchNo",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "物流公司",
                        "label-width": _vm.formLabelWidth,
                        prop: "logistics",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.exportform.logisticsName,
                            callback: function ($$v) {
                              _vm.$set(_vm.exportform, "logisticsName", $$v)
                            },
                            expression: "exportform.logisticsName",
                          },
                        },
                        _vm._l(_vm.companylogistics, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.valueCn, value: item.valueCn },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "物流单号",
                        "label-width": _vm.formLabelWidth,
                        prop: "number",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { autocomplete: "off" },
                        model: {
                          value: _vm.exportform.logisticsNumber,
                          callback: function ($$v) {
                            _vm.$set(_vm.exportform, "logisticsNumber", $$v)
                          },
                          expression: "exportform.logisticsNumber",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.exportFlag
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "方式",
                            "label-width": _vm.formLabelWidth,
                            prop: "remark",
                          },
                        },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { label: "1" },
                              on: { change: _vm.allout },
                              model: {
                                value: _vm.exportform.stockOutType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.exportform, "stockOutType", $$v)
                                },
                                expression: "exportform.stockOutType",
                              },
                            },
                            [_vm._v(_vm._s(_vm.allouts))]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-radio",
                            {
                              attrs: { label: "2" },
                              on: { change: _vm.allout },
                              model: {
                                value: _vm.exportform.stockOutType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.exportform, "stockOutType", $$v)
                                },
                                expression: "exportform.stockOutType",
                              },
                            },
                            [_vm._v(_vm._s(_vm.aloneout))]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.numFlag
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "数量",
                            "label-width": _vm.formLabelWidth,
                            prop: "counts",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { autocomplete: "off" },
                            model: {
                              value: _vm.exportform.counts,
                              callback: function ($$v) {
                                _vm.$set(_vm.exportform, "counts", $$v)
                              },
                              expression: "exportform.counts",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.exportFlag
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "起始编码",
                            "label-width": _vm.formLabelWidth,
                            prop: "startNumber",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticStyle: { display: "flex" } },
                            [
                              _c("el-input", {
                                attrs: { autocomplete: "off" },
                                model: {
                                  value: _vm.exportform.startNumber,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.exportform, "startNumber", $$v)
                                  },
                                  expression: "exportform.startNumber",
                                },
                              }),
                              _vm._v(" "),
                              _vm.numberflag
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "font-size": "30px",
                                        "padding-left": "10px",
                                        cursor: "pointer",
                                      },
                                      on: { click: _vm.addNumber },
                                    },
                                    [_vm._v("+")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.exportFlag
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "",
                            "label-width": _vm.formLabelWidth,
                            prop: "numberList",
                          },
                        },
                        _vm._l(
                          _vm.exportform.numberList,
                          function (item, index) {
                            return _c(
                              "div",
                              { key: index, staticStyle: { display: "flex" } },
                              [
                                _c("el-input", {
                                  staticStyle: { "margin-top": "10px" },
                                  attrs: { autocomplete: "off" },
                                  model: {
                                    value: item.text,
                                    callback: function ($$v) {
                                      _vm.$set(item, "text", $$v)
                                    },
                                    expression: "item.text",
                                  },
                                }),
                                _vm._v(" "),
                                _vm.removeFlag
                                  ? _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          "font-size": "30px",
                                          "padding-left": "10px",
                                          cursor: "pointer",
                                          "margin-top": "10px",
                                        },
                                        on: {
                                          click: function () {
                                            return _vm.removeNumber(index)
                                          },
                                        },
                                      },
                                      [_vm._v("-")]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          }
                        ),
                        0
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "备注",
                        "label-width": _vm.formLabelWidth,
                        prop: "remark",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { autocomplete: "off" },
                        model: {
                          value: _vm.exportform.remark,
                          callback: function ($$v) {
                            _vm.$set(_vm.exportform, "remark", $$v)
                          },
                          expression: "exportform.remark",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.exportClose("exportform")
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.surcLoading,
                      expression: "surcLoading",
                    },
                  ],
                  attrs: { type: "primary", disabled: _vm.isDisable },
                  on: {
                    click: function ($event) {
                      return _vm.exportSure("exportform")
                    },
                  },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "批出",
            visible: _vm.dialogoutForm,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogoutForm = $event
            },
            close: _vm.outClose,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "outform",
              attrs: {
                model: _vm.outform,
                rules: _vm.outformRules,
                "status-icon": true,
                "show-message": false,
                "hide-required-asterisk": "",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "品质仓",
                    "label-width": _vm.formLabelWidth,
                    prop: "position",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.outform.position,
                        callback: function ($$v) {
                          _vm.$set(_vm.outform, "position", $$v)
                        },
                        expression: "outform.position",
                      },
                    },
                    _vm._l(_vm.dealers, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.valueCn, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "locationPosition",
                    label: "区域仓",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.outform.locationPosition,
                        callback: function ($$v) {
                          _vm.$set(_vm.outform, "locationPosition", $$v)
                        },
                        expression: "outform.locationPosition",
                      },
                    },
                    _vm._l(_vm.locationPositions, function (item, index) {
                      return _c("el-option", {
                        attrs: { label: item.valueCn, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "经销商",
                    "label-width": _vm.formLabelWidth,
                    prop: "dealerId",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.outform.dealerId,
                        callback: function ($$v) {
                          _vm.$set(_vm.outform, "dealerId", $$v)
                        },
                        expression: "outform.dealerId",
                      },
                    },
                    _vm._l(_vm.dealerLists, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.valueCn, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "设备用途",
                    "label-width": _vm.formLabelWidth,
                    prop: "equipmentPurpose",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.outform.equipmentPurpose,
                        callback: function ($$v) {
                          _vm.$set(_vm.outform, "equipmentPurpose", $$v)
                        },
                        expression: "outform.equipmentPurpose",
                      },
                    },
                    _vm._l(_vm.equipmentPurposes, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.valueCn, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "物流公司",
                    "label-width": _vm.formLabelWidth,
                    prop: "logistics",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.outform.logistics,
                        callback: function ($$v) {
                          _vm.$set(_vm.outform, "logistics", $$v)
                        },
                        expression: "outform.logistics",
                      },
                    },
                    _vm._l(_vm.companylogistics, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.valueCn, value: item.valueCn },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "物流单号",
                    "label-width": _vm.formLabelWidth,
                    prop: "number",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.outform.number,
                      callback: function ($$v) {
                        _vm.$set(_vm.outform, "number", $$v)
                      },
                      expression: "outform.number",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "备注",
                    "label-width": _vm.formLabelWidth,
                    prop: "remark",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.outform.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.outform, "remark", $$v)
                      },
                      expression: "outform.remark",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "设备导入",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "upload-wrappers" },
                    [
                      _c("input", {
                        ref: "outFile",
                        staticClass: "upload-files",
                        attrs: {
                          id: "file",
                          type: "file",
                          name: "file",
                          multiple: "",
                        },
                        on: { change: _vm.outUpload },
                      }),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticClass: "exprot-btn",
                          attrs: { size: "small", type: "primary" },
                        },
                        [_c("span", [_vm._v("点击上传")])]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.outClose } }, [
                _vm._v("取 消"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "品质仓调整",
            visible: _vm.positionDialogsVisible,
            "close-on-click-modal": false,
            width: "40%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.positionDialogsVisible = $event
            },
            close: _vm.positionClose,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "formUpdate",
              staticStyle: { "margin-right": "40px" },
              attrs: {
                model: _vm.formUpdate,
                rules: _vm.formUpdateRules,
                "status-icon": true,
                "show-message": false,
                "hide-required-asterisk": "",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "产品编号",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c("el-input", {
                    attrs: { disabled: "", autocomplete: "off" },
                    model: {
                      value: _vm.formUpdate.formProductNo,
                      callback: function ($$v) {
                        _vm.$set(_vm.formUpdate, "formProductNo", $$v)
                      },
                      expression: "formUpdate.formProductNo",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "产品名称",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c("el-input", {
                    attrs: { disabled: "", autocomplete: "off" },
                    model: {
                      value: _vm.formUpdate.formProductName,
                      callback: function ($$v) {
                        _vm.$set(_vm.formUpdate, "formProductName", $$v)
                      },
                      expression: "formUpdate.formProductName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: { label: "品质仓", "label-width": _vm.formLabelWidth },
                },
                [
                  _c("el-input", {
                    attrs: { disabled: "", autocomplete: "off" },
                    model: {
                      value: _vm.formUpdate.formPosition,
                      callback: function ($$v) {
                        _vm.$set(_vm.formUpdate, "formPosition", $$v)
                      },
                      expression: "formUpdate.formPosition",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "可发货数量",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c("el-input", {
                    attrs: { disabled: "", autocomplete: "off" },
                    model: {
                      value: _vm.formUpdate.formCount,
                      callback: function ($$v) {
                        _vm.$set(_vm.formUpdate, "formCount", $$v)
                      },
                      expression: "formUpdate.formCount",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    prop: "position",
                    label: "目标品质仓",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.formUpdate.position,
                        callback: function ($$v) {
                          _vm.$set(_vm.formUpdate, "position", $$v)
                        },
                        expression: "formUpdate.position",
                      },
                    },
                    _vm._l(_vm.tableDatas, function (item, index) {
                      return _c("el-option", {
                        attrs: { label: item.valueCn, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    label: "目标数量",
                    "label-width": _vm.formLabelWidth,
                    prop: "counts",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { type: "text" },
                    model: {
                      value: _vm.formUpdate.counts,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formUpdate,
                          "counts",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formUpdate.counts",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.positionClose } }, [
                _vm._v("取消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: _vm.isDisable },
                  on: { click: _vm.positionAdd },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "区域仓调整",
            visible: _vm.locationDialogsVisible,
            "close-on-click-modal": false,
            width: "40%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.locationDialogsVisible = $event
            },
            close: _vm.locationClose,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "formUpdate",
              staticStyle: { "margin-right": "40px" },
              attrs: {
                model: _vm.formUpdate,
                rules: _vm.locationRules,
                "status-icon": true,
                "show-message": false,
                "hide-required-asterisk": "",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "产品编号",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c("el-input", {
                    attrs: { disabled: "", autocomplete: "off" },
                    model: {
                      value: _vm.formUpdate.formProductNo,
                      callback: function ($$v) {
                        _vm.$set(_vm.formUpdate, "formProductNo", $$v)
                      },
                      expression: "formUpdate.formProductNo",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "产品名称",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c("el-input", {
                    attrs: { disabled: "", autocomplete: "off" },
                    model: {
                      value: _vm.formUpdate.formProductName,
                      callback: function ($$v) {
                        _vm.$set(_vm.formUpdate, "formProductName", $$v)
                      },
                      expression: "formUpdate.formProductName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: { label: "区域仓", "label-width": _vm.formLabelWidth },
                },
                [
                  _c("el-input", {
                    attrs: { disabled: "", autocomplete: "off" },
                    model: {
                      value: _vm.formUpdate.formlocationPosition,
                      callback: function ($$v) {
                        _vm.$set(_vm.formUpdate, "formlocationPosition", $$v)
                      },
                      expression: "formUpdate.formlocationPosition",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "可发货数量",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c("el-input", {
                    attrs: { disabled: "", autocomplete: "off" },
                    model: {
                      value: _vm.formUpdate.formCount,
                      callback: function ($$v) {
                        _vm.$set(_vm.formUpdate, "formCount", $$v)
                      },
                      expression: "formUpdate.formCount",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    prop: "locationPosition",
                    label: "目标区域仓",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.formUpdate.locationPosition,
                        callback: function ($$v) {
                          _vm.$set(_vm.formUpdate, "locationPosition", $$v)
                        },
                        expression: "formUpdate.locationPosition",
                      },
                    },
                    _vm._l(_vm.locationPositions, function (item, index) {
                      return _c("el-option", {
                        attrs: { label: item.valueCn, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    label: "目标数量",
                    "label-width": _vm.formLabelWidth,
                    prop: "counts",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { type: "text" },
                    model: {
                      value: _vm.formUpdate.counts,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formUpdate,
                          "counts",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formUpdate.counts",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.locationClose } }, [
                _vm._v("取消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: _vm.isDisable },
                  on: { click: _vm.locationAdd },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.dialogTitle,
            visible: _vm.dialogSkuCount,
            width: "60%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogSkuCount = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.usCountloading,
                  expression: "usCountloading",
                },
              ],
              staticStyle: { width: "100%", "margin-top": "10px" },
              attrs: {
                data: _vm.skuList,
                "row-style": _vm.changeRowColor,
                border: "",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "产品编号",
                  "show-overflow-tooltip": "",
                  prop: "productNo",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "产品名称",
                  "show-overflow-tooltip": "",
                  prop: "productName",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "区域仓",
                  "show-overflow-tooltip": "",
                  prop: "locationPositionName",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "可发货数量",
                  "show-overflow-tooltip": "",
                  prop: "counts",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "美国仓库存",
                  "show-overflow-tooltip": "",
                  prop: "usCounts",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "差值",
                  "show-overflow-tooltip": "",
                  prop: "countDifferences",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "page" },
            [
              _vm.totalsku != 0
                ? _c("el-pagination", {
                    attrs: {
                      background: "",
                      layout: " total, prev, pager, next",
                      "page-size": 10,
                      total: _vm.totalsku,
                      "current-page": _vm.uscurrentPage,
                    },
                    on: { "current-change": _vm.uschangepage },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogSkuCount = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.dialogSkuCount = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "page" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "total, prev, pager, next",
              "page-size": 10,
              total: _vm.total,
              "current-page": _vm.currentPage,
            },
            on: { "current-change": _vm.changepage },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }